import React from 'react';
import { Helmet } from 'react-helmet';

import Overview from 'components/Dashboard/Overview';
import Layout from 'components/layout';

const OverviewPage: React.FC = () => (
  <Layout userLogin>
    <Helmet>
      <title>Overview</title>
      <meta name="og:title" content="Overview" />
      <meta name="twitter:title" content="Overview" />
      <body className="dashboard" />
    </Helmet>
    <Overview.Container>
      <Overview.Cards />
      <Overview.Charts />
    </Overview.Container>
  </Layout>
);

export default OverviewPage;
