import React from 'react';

import styles from './OverviewCard.module.scss';

export interface OverviewCardProps {
  data: {
    amount: number;
    percentage: number;
    description: string;
    date?: string;
  };
}

const OverviewCard: React.FC<OverviewCardProps> = ({ data }) => {
  const isPercentagePositive = data.percentage > 0;
  const percentage = Math.abs(data.percentage);

  return (
    <div className={`col-lg-3 col-md-6 col-12 ${styles.card}`}>
      <span className={styles.amount}>
        {data.amount}
      </span>
      <div>
        <span className={`
          ${styles.percentage} 
          ${isPercentagePositive ? styles.positive : styles.negative}
        `}
        >
          {percentage}
          %
        </span>
      </div>
      <span className={styles.description}>
        {data.description}
      </span>
      {data.date && (
        <span className={styles.date}>
          {data.date}
        </span>
      )}
    </div>
  );
};

export default OverviewCard;
