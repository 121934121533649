export const pieChartData = [
  {
    id: 'France',
    label: 'France',
    value: 35,
    color: 'rgb(156, 206, 255)',
  },
  {
    id: 'Russia',
    label: 'Russia',
    value: 29,
    color: 'rgb(88, 157, 255)',
  },
  {
    id: 'Germany',
    label: 'Germany',
    value: 11,
    color: 'rgb(36, 109, 255',
  },
  {
    id: 'Italy',
    label: 'Italy',
    value: 10,
    color: 'rgb(11, 1, 255',
  },
  {
    id: 'Estonia',
    label: 'Estonia',
    value: 8,
    color: 'rgb(0, 43, 146)',
  },
  {
    id: 'Other countries',
    label: 'Other countries',
    value: 8,
    color: 'rgb(1, 0, 0)',
  },
];

export const doughnutChartData1 = [
  {
    id: '1st type',
    label: '1st type',
    value: 2577,
    color: 'rgb(156, 206, 255)',
    description: '1st type users - users who installed the application at your invitation for the 1st time.',
  },
  {
    id: '2nd type',
    label: '2nd type',
    value: 2138,
    color: 'rgb(36, 109, 255)',
    description: '2st type users - current users who become active again after your invitation.',
  },
  {
    id: '3rd type',
    label: '3rd type',
    value: 767,
    color: 'rgb(0, 43, 136)',
    description: '3rd type users - users who are already active before your invitation.',
  },
];

export const doughnutChartData2 = [
  {
    id: 'Active',
    label: 'Active',
    value: 1863,
    color: 'rgb(104, 159, 48)',
    description: 'explain who the active users are.',
  },
  {
    id: 'Not active',
    label: 'Not active',
    value: 3015,
    color: 'rgb(244, 184, 0)',
    description: 'explain who the not active users are.',
  },
  {
    id: 'Fake accounts',
    label: 'Fake accounts',
    value: 657,
    color: 'rgb(299, 60, 45)',
    description: 'explain who the fake users are.',
  },
];
