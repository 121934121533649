import React from 'react';

import OverviewContainer from 'components/Dashboard/Overview/OverviewContainer';

import OverviewCard from './OverviewCard';
import OverviewCards from './OverviewCards';
import OverviewCharts from './OverviewCharts';

export interface OverviewProps extends React.FC {
  Card: typeof OverviewCard;
  Cards: typeof OverviewCards;
  Container: typeof OverviewContainer;
  Charts: typeof OverviewCharts;
}

const Overview: OverviewProps = ({ children }) => (
  <div>
    {children}
  </div>
);

Overview.Container = OverviewContainer;
Overview.Card = OverviewCard;
Overview.Cards = OverviewCards;
Overview.Charts = OverviewCharts;

export default Overview;
