import * as React from 'react';

import styles from '../Overview.module.scss';

const OverviewContainer: React.FC = ({ children }) => (
  <div className={`container ${styles.container}`}>
    {children}
  </div>
);

export default OverviewContainer;
