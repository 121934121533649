import * as React from 'react';

import PieChart from 'components/UI/Charts/PieChart';

import { pieChartData, doughnutChartData1, doughnutChartData2 } from './mockData';

const OverviewCharts: React.FC = () => (
  <div>
    <PieChart data={pieChartData} />
    <PieChart data={doughnutChartData1} type="doughnut" />
    <PieChart data={doughnutChartData2} type="doughnut" />
  </div>
);

export default OverviewCharts;
