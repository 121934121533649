import * as React from 'react';

import Overview from '..';
import styles from '../Overview.module.scss';

import { cardsMockData } from './mockData';

const OverviewCards: React.FC = () => (
  <div className={`${styles.cards} row`}>
    {cardsMockData.map((data) => <Overview.Card data={data} />)}
  </div>
);

export default OverviewCards;
