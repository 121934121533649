import { ResponsivePie } from '@nivo/pie';
import * as React from 'react';

import styles from './PieChart.module.scss';

export interface PieChartProps {
  data: Array<ChartData>;
  type?: 'doughnut';
}

const PieChart: React.FC<PieChartProps> = ({ data, type }) => {
  const percentageCalc = (number: number): string => {
    const totalItemsAmount = data.map((item) => item.value).reduce((a, b) => a + b);
    const percentage = `${((number / totalItemsAmount) * 100).toFixed(2)}%`;

    return percentage;
  };

  return (
    <div className={styles.chartBox}>
      <ResponsivePie
        data={data}
        margin={{
          top: 40, bottom: 80, left: 170, right: 170,
        }}
        startAngle={0}
        sortByValue={false}
        innerRadius={type ? 0.75 : 0}
        borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
        radialLabel={(e): string => `${e.id} ${percentageCalc(e.value)} - ${e.value} users`}
        sliceLabel={(e): string => `${e.value}%`}
        radialLabelsTextXOffset={13}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={5}
        radialLabelsLinkDiagonalLength={10}
        radialLabelsLinkHorizontalLength={5}
        radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
        enableRadialLabels={!!type}
        enableSliceLabels={!type}
        sliceLabelsRadiusOffset={0.7}
        sliceLabelsSkipAngle={0}
        sliceLabelsTextColor="#ffffff"
        colors={{ datum: 'data.color' }}
        theme={{
          labels: {
            text: {
              fontSize: type ? 12 : 20,
            },
          },
        }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
      />
      <ul className={styles.chartBoxLegends}>
        {data.map((item) => (
          <li key={item.id} className="d-flex align-items-start">
            <div className={styles.colorBox} style={{ backgroundColor: item.color }} />
            <div>
              <div>
                <b>{item.label}</b>
                {item.description && ` - ${item.description}`}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PieChart;
