export const cardsMockData = [
  {
    amount: 240,
    percentage: 14,
    description: 'Total users invited',
  },
  {
    amount: 163,
    percentage: 23,
    description: 'Total users that bring you money',
  },
  {
    amount: 378,
    percentage: 23,
    description: 'Total earnings till today',
  },
  {
    amount: 278,
    percentage: -53,
    description: 'Earning this month',
  },
  {
    amount: 640,
    percentage: 14,
    description: 'Privious payout',
    date: '(22/03/2021)',
  },
  {
    amount: 163,
    percentage: 22,
    description: 'Estimated next payout',
    date: '(22/04/2021)',
  },
  {
    amount: 121,
    percentage: 23,
    description: 'Total earning from 2nd level',
  },
  {
    amount: 203,
    percentage: -53,
    description: 'Total earning from 3rd level',
  },
];

export const userDetailMockData = [
  {
    id: '1',
    image: 'https://i.pravatar.cc/200?img=47',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'active',
    type: '1st type',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
  {
    id: '2',
    image: 'https://i.pravatar.cc/200?img=49',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'analyzing',
    type: '',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
  {
    id: '3',
    image: 'https://i.pravatar.cc/200?img=52',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'not active',
    type: '1st type',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
  {
    id: '4',
    image: 'https://i.pravatar.cc/200?img=57',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'suspicious accounts',
    type: '1st type',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
];
